.plus-column-button {
    font-size: 18px;
    color: #1890ff;
    background-color: #f0f0f0; /* Example background */
    border-radius: 50%;
    border-style: dashed;
    border-color: black;
    padding: 4px;
    transition: all 0.3s;
  }
  
  .plus-column-button:hover {
    color: #40a9ff;
    background-color: #e6f7ff;

  }