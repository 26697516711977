.unsaved-changes-banner {
    position: fixed;
    top: 0;
    left: 30%;
    width: 30%;
    background-color: #fff3cd; /* Light yellow background */
    padding: 10px;
    border-bottom: 1px solid #ffeeba; /* Light yellow border */
    z-index: 1050; /* Ensures the banner stays on top */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  }


  .section-selector {
    color: white; /* White text for contrast */
    font-weight: bold; /* Bold text */
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
    cursor: pointer; /* Show pointer cursor to indicate interactivity */
  }

  .section-selector.multiple-sections {
    border: 1px dashed blue; /* Blue dashed border for multiple sections */
  }
  
  .section-selector:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  .section-selector:active {
    transform: scale(1); /* Reset zoom effect on click */
  }