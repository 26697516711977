.field-builder-modal {
    z-index: 900;
}

.ant-modal {
    z-index: 900; /* Lower than the tooltip */
  }

.ace_valid-field {
background-color: rgba(0, 255, 0, 0.2); /* Light green */
position: absolute;
}

.ace_invalid-field {
background-color: rgba(255, 0, 0, 0.2); /* Light red */
position: absolute;
}