/* styles.css */

.stage-builder-modal .ant-modal-content {
    width: 80vw;                /* Responsive width */
    max-width: 1200px;          /* Set a maximum width */
    left: 50%;                  /* Position left at 50% of viewport */
    transform: translateX(-50%);/* Shift left by 50% of its own width to center */
}

 .stage-builder-row {
    height: 10px;
    padding: 1px;
    margin: 1px;
 }

 .stage-builder-cell {
    padding: 1px;                /* Adjust padding as needed */
    display: flex;
    align-items: center;         /* Vertically center content */
    height: 2vh;                /* Ensure it matches row height */
}

.stage-builder-row:nth-child(odd) {
    background-color: #fafafa;  /* Alternate row colors */
}


.stage-field-names {
    font-weight: bold;
}


.stage-columns-header-cell { 
    width: 100vw;
}

.stage-name-input {
    text-wrap:wrap;
    max-width: 100px;
}