/* Sidebar container */
/* .profile-selector {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
  } */
  
  .profile-content {
    /* Styles for other profile-related content */
    flex-grow: 1;  /* This will take the available space above the logout button */
  }

  /* Logout button styles */
  .logout-btn {
    background-color: #ff4d4d;  /* Red background for logout */
    color: white;                /* White text */
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;  /* Pushes the button to the bottom of the sidebar */
    width: 100%;  /* Makes the button take full width */
    height: auto;
  }

  .settings-btn {
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;  /* Pushes the button to the bottom of the sidebar */
    width: 100%;  /* Makes the button take full width */
    height: auto;
  }
  
  .logout-btn:hover {
    background-color: #e04343;  /* Darker red on hover */
  }
  
  .logout-btn:focus {
    outline: none;  /* Removes focus outline */
    padding: 10px;
    margin-bottom: 5px;
  }

  .scrollable-slider {
    height: calc(100vh - 1px);
    overflow-y: auto;
  }