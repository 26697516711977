/* styles.css */

/* General Styles for Sections */
.section-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    min-height: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .section-container.dragging-over {
    border: 2px dashed #007bff;
    background-color: #f7faff;
  }
  
  /* Title Styles */
  .section-title {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 12px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  /* .section-title:hover {
    color: #007bff;
  } */
  
  /* Styles for Fields */
  .field-item {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .field-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  
  .field-item.dragging {
    border: 2px dashed #ff4d4f;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .field-delete-btn {
    font-size: 0.85em;
    color: #ff4d4f;
    border: none;
    background: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .field-delete-btn:hover {
    color: #d32f2f;
  }

  .row-container {
    display: flex;
    justify-content: space-between;
    gap: 12px; /* Space between columns */
  }
  
  .column-item {
    flex: 1;
    margin: 0; /* Remove extra margin */
  }