#billing-plan-group {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 3.5rem;
}

#billing-plan-group .ant-radio-button-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

#billing-plan-group .ant-radio-button-wrapper:hover {
    border-color: #1890ff;
}

#billing-plan-group .ant-radio-button-wrapper-checked {
    border-color: #1890ff;
    background-color: #e6f7ff;
}

.radio-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*margin-bottom: 1rem;*/
}

.radio-title {
    font-size: 16px;
    font-weight: bold;
    /*margin-bottom: 0.25rem;*/
}

.radio-subtext {
    color: #1890ff;
    font-size: 14px;
}

.radio-extra {
    /*margin-top: 0.25rem;*/
    font-size: 12px;
    color: #52c41a;
}

#license-count {
    margin-bottom: 0.25rem;
    text-align: center;
    /*padding: 0.25rem;*/
}

#checkout-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    margin-top: 0.5rem;
}