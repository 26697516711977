/* General page layout */
.page-container {
    width: 80%;
    margin: 0 auto;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Title */
  .page-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Inputs, select fields */
  .input-field,
  .select-field {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  /* Button styles */
  .primary-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .primary-btn:hover {
    background-color: #45a049;
  }
  
  .secondary-btn {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .secondary-btn:hover {
    background-color: #ddd;
  }
  
  /* Section layout */
  .section-container {
    margin-bottom: 40px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .field-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .field-item {
    flex: 1 1 45%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .field-item .field-name {
    font-weight: bold;
  }
  
  .field-item button {
    background-color: #ff6666;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .field-item button:hover {
    background-color: #ff4d4d;
  }
  
  /* Spacing adjustments */
  .field-palette {
    margin-bottom: 30px;
  }
  
  .save-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .selected-row {
    background-color: #e6f7ff !important;  /* Light blue background for selected rows */
  }

  .action-button {
    /*padding-right: 10px;*/
    margin-right: 10px;
    /*border-color: blue;*/
  }

  .delete-button {
    border-color: red;
  }